<template>
    <div id="app" class="fullscreen">
      <iframe 
        src="https://sim.teste.suita.com.br/arqmidia/galeria.html#" 
        frameborder="0" 
        class="iframe-fullscreen">
      </iframe>
    </div>
  </template>
  
  <script>
  import { mapMutations } from "vuex";
  export default {
    data() {
    return {
    name: "FullScreenIframe"
    }
  },
  methods: {
    ...mapMutations(["OCULTAR_MENU"]),
  },
  beforeMount() {
    this.OCULTAR_MENU();
  }
  };


  </script>
  
  <style scoped>
  .fullscreen {
    height: 100vh; /* Altura de 100% da viewport */
    width: 100vw;  /* Largura de 100% da viewport */
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .iframe-fullscreen {
    height: 100%;
    width: 100%;
    border: none;
  }
  </style>
  